import React from 'react'

import moment from 'moment';

import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import ExpandingTablePanel from './ExpandingTablePanel'
import ExpandingList from './ExpandingList'
import NumberPanels from './NumberPanels'
import { dataProvider } from '../App'
import { showNotification } from 'react-admin';

import MomentUtils from '@date-io/moment';
import {
    DatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import './Dashboard.css'

class Dashboard extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            filter: 1,
            expanded: false,
            totalInvocations: 0,
            tipsInvocations: 0,
            emailsSent: 0,
            qaInvocations: 0,
            eventErrors: [],
            faqErrors: [],
            customPeriod: false,
            start: moment().subtract(1, 'weeks').startOf('day'),
            end: moment().endOf('day')
        }
    }

    componentDidMount = () => {
        this.pageRequest()
    } 

    selectOnChange = (event) => {
        const selectValue = event.target.value
        let { customPeriod, start, end } = this.state

        if (selectValue === 3) {
            customPeriod = true
        } else {
            customPeriod = false
        }

        if (selectValue === 1) {
            start = moment().subtract(1, 'weeks').startOf('day')
            end = moment().endOf('day')
        }
        else if (selectValue === 2) {
            start = moment().subtract(1, 'months').startOf('day')
            end = moment().endOf('day')
        }

        this.setState({
            filter: selectValue,
            customPeriod,
            start,
            end
        }, () => {
            if (selectValue !== 3) {
                this.pageRequest()
            }
        })
    }

    togglePanel = () => {
        this.setState({
            expanded: !this.state.expanded
        })
    }

    pageRequest = () => {
        const { start, end } = this.state
        const query = { start: start.unix(), end: end.unix() }
        Promise.all([
            dataProvider('PECAN_INVOCATIONS_COUNT', 'dashboard', query),
            dataProvider('TIPS_INVOCATIONS_COUNT', 'dashboard', query),
            dataProvider('EMAILS_SENT_COUNT', 'dashboard', query),
            dataProvider('QA_INVOCATIONS_COUNT', 'dashboard', query)

        ]).then(response => {
            this.setState({
                totalInvocations: response[0].count,
                tipsInvocations: response[1].count,
                emailsSent: response[2].count,
                qaInvocations: response[3].count
            })
        })
        dataProvider('FAQ_ERRORS', 'dashboard', query).then(response => {
            this.setState({
                faqErrors: response.errors
            })
        })
        dataProvider('EVENT_ERRORS', 'dashboard', query).then(response => {
            this.setState({
                eventErrors: response.errors
            })
        })
    }

    handleDateChange = (date, name) => {
        if (name === 'end') {
            date = date.endOf('day')
        } else {
            date = date.startOf('day')
        }

        this.setState({
            [name]: date
        }, () => {
            const { start, end } = this.state
            if (start > end) {
                showNotification('Start date cannot be more than end date')
                return
            }
            if (end < start) {
                showNotification('End date cannot be more than start date')
                return
            }
            if ((start && end) && start <= end) {
                this.pageRequest()
            }
        })
    }

    render() {
        const { 
            filter,
            totalInvocations,
            tipsInvocations,
            emailsSent,
            qaInvocations,
            eventErrors,
            faqErrors,
            customPeriod
        } = this.state
        const faqErrorsTitle = `Top-${faqErrors.length} errors for FAQ`
        return (
            <div className='dashboard-page'>
                <div className="headers">
                    <div className="dashboard-title">
                        <h1 className="">Dashboard</h1>
                        <a href="https://developer.amazon.com/alexa/console/ask/measure/amzn1.ask.skill.af67b9ae-bfb6-4245-840f-e1f85253dc56/development/en_US/summary" target="_blank">
                            AWS Alexa Developer Console
                        </a>
                    </div>
                    <div className="dates-panel">
                        {customPeriod && <div className="custom-dates">
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <DatePicker
                                    variant="inline"
                                    value={moment(this.state.start)}
                                    onChange={(val) => this.handleDateChange(val, 'start')}
                                    format="MM/DD/YYYY"
                                    name="start" />
                                <DatePicker
                                    variant="inline"
                                    value={moment(this.state.end)}
                                    onChange={(val) => this.handleDateChange(val, 'end')}
                                    format="MM/DD/YYYY"
                                    name="end" />
                            </MuiPickersUtilsProvider>
                        </div>}
                        <FormControl>
                            <InputLabel htmlFor="filter-simple">Time period</InputLabel>
                            <Select
                                value={filter}
                                onChange={this.selectOnChange}
                                inputProps={{
                                    name: 'filter',
                                }}
                            >
                                <MenuItem value={1}>This Week</MenuItem>
                                <MenuItem value={2}>This Month</MenuItem>
                                <MenuItem value={3}>Selected Period</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <NumberPanels 
                    invocations={totalInvocations} 
                    tipsinvocations={tipsInvocations}
                    emailssent={emailsSent}
                    qainvocations={qaInvocations} />
                <div className="content">
                    <ExpandingTablePanel data={eventErrors} />
                    <ExpandingList 
                        title="FAQs errors" 
                        shorttitle={faqErrorsTitle}
                        data={faqErrors} />
                </div>
            </div>
        )
    }
}


export default Dashboard