import React from 'react'

import {
    List,
    Datagrid,
    TextField,
    DateField,
    TextInput,
    EditButton,
    Filter,
} from 'react-admin';

const TipsFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="search" alwaysOn />
    </Filter>
);

export const TipsList = (props) => (
    <List {...props} filters={<TipsFilter />} exporter={false} sort={{ field: 'created_at', order: 'DESC' }}>
        <Datagrid rowClick="show">
            <TextField source="name" />
            <TextField source="text" />
            <DateField source="created_at" label="Created" locales="en-US" />
            <EditButton />
        </Datagrid>
    </List>
);