import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { Admin, Resource, Login, Sidebar, Layout, Menu } from 'react-admin';
import { Route } from 'react-router-dom';
import { createBrowserHistory as createHistory } from 'history';
import englishMessages from 'ra-language-english';
import drfProvider from 'ra-data-drf';
import { createMuiTheme } from '@material-ui/core/styles';

import { apiHost } from './api';
import './App.css';
import { tips } from './tips/index';
import { questions } from './questionsanswers/index';
import { httpClient } from './httpClient';
import { customDataProvider } from './dataProvider';

import authProvider from './authProvider';

import NotFound from './NotFound';
import Dashboard from './dashboard/Dashboard'
import MyMenu from './Menu'
import MyAppBar from './AppBar'
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import blue from '@material-ui/core/colors/blue';

const history = createHistory();

export const dataProvider = customDataProvider(drfProvider(apiHost, httpClient))


const CustomSidebar = props => {
  return(
    <Sidebar {...props} size={200}/>
  )
};
const MyLoginPage = () => <Login backgroundImage={null} />;

const CustomLayout = props => <Layout
  {...props}
  menu={MyMenu}
  appBar={MyAppBar}
  sidebar={CustomSidebar}
/>;

const routes = [
  <Route exact path="/dashboard" component={() => <Dashboard/>} />,
];

const messages = {
  ...englishMessages,
  ra: {
    notification: {
      answer: {
        updated: 'Question was succesfully updated',
        created: 'New question was successfully created'
      },
      tips: {
        updated: 'Tip was succesfully updated',
        created: 'New tip was successfully created'
      }
    }
  }
}

const i18nProvider = locale => messages


function App() {
  return (
      <Admin
        appLayout={CustomLayout}
        i18nProvider={i18nProvider} 
        customRoutes={routes} 
        history={history} 
        catchAll={NotFound} 
        loginPage={MyLoginPage} 
        authProvider={authProvider} 
        dataProvider={dataProvider}>
        <Resource 
          name="tips" 
          icon={<PermContactCalendarIcon />}
          options={{ 'label': 'Tips' }}
          {...tips} />
        <Resource 
          name="answers" 
          options={{ 'label': 'Questions' }} 
          icon={<LiveHelpIcon />}
          {...questions} />
      </Admin>
  );
}

export default App;
