import React from 'react'

import {
    TextField,
    Show,
    SimpleShowLayout,
} from 'react-admin';

export const TipShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="name" />
            <TextField source="text" />
        </SimpleShowLayout>
    </Show >
);