import React from 'react'
import Button from '@material-ui/core/Button';
import { getFormValues } from 'redux-form';
import { TextInput, } from 'react-admin';

export const formsAreSimilar = (prevForm, newForm) => {
    let result = true
    Object.keys(prevForm).forEach(key => {
        if (prevForm[key] !== newForm[key]) {
            result = false
        }
    })
    return result
}

export const CancelButtonView = ({
    onClick,
}) => (
        <Button onClick={onClick}>
            Cancel
        </Button>
);

export const mapStateToProps = state => ({
    form: getFormValues('record-form')(state)
});

export const required = (message = "This field couldn't be blank") =>
    value => value ? undefined : message;

export const CustomTextField = (props) => (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
        <label>{props.baselabel}</label>
        <TextInput source={props.source} label={props.textlabel} validate={props.validate} />
    </div>
);