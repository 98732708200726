import { fetchUtils } from 'react-admin';

export const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    // add your own headers here
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Token ${token}`);
    return fetchUtils.fetchJson(url, options);
}