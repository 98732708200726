import React from 'react';

import {
    Edit,
    SimpleForm,
    TextInput,
    LongTextInput,
} from 'react-admin';

import TipEditToolbar from './EditTipToolbar'
import { required } from '../utils'

export const TipEdit = (props) => (
    <Edit {...props}>
        <SimpleForm toolbar={<TipEditToolbar />}>
            <TextInput source="name" validate={required()} />
            <LongTextInput source="text" validate={required()} />
        </SimpleForm>
    </Edit>
);