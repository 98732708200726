import { apiHost } from './api'
import { stringify } from 'query-string';

export const customDataProvider = requestHandler => (type, resource, params) => {
    if (resource === 'dashboard') {
        const { start, end } = params
        let url = ''
        const token = localStorage.getItem('token')
        const options = {
            headers: {
                'Content-Type': 'application/json'
            },
        };
        const query = {
            start,
            end
        }
        if (token) {
            options.headers['authorization'] = `Token ${token}`
        }
        if (type == 'PECAN_INVOCATIONS_COUNT') {
            return fetch(`${apiHost}/analytics/total-invocations-count?${stringify(query)}`, options)
                    .then(res => res.json())
        }
        if (type == 'TIPS_INVOCATIONS_COUNT') {
            return fetch(`${apiHost}/analytics/tips-invocations-count?${stringify(query)}`, options)
                .then(res => res.json())
        }
        if (type == 'EMAILS_SENT_COUNT') {
            return fetch(`${apiHost}/analytics/emails-sent-count?${stringify(query)}`, options)
                .then(res => res.json())
        }
        if (type == 'QA_INVOCATIONS_COUNT') {
            return fetch(`${apiHost}/analytics/qa-invocations-count?${stringify(query)}`, options)
                .then(res => res.json())
        }
        if(type == 'FAQ_ERRORS') {
            return fetch(`${apiHost}/analytics/faq-errors?${stringify(query)}`, options)
                .then(res => res.json())
        }
        if (type == 'EVENT_ERRORS') {
            return fetch(`${apiHost}/analytics/event-errors?${stringify(query)}`, options)
                .then(res => res.json())
        }
    }
    return requestHandler(type, resource, params);
}