import React from 'react';
import { connect } from 'react-redux';

import { formsAreSimilar, mapStateToProps, CancelButtonView } from '../utils'

import {
    Confirm,
    SaveButton,
    Toolbar,
    UPDATE
} from 'react-admin';

const updateTip = (
    resource,
    id,
    data,
    previousData,
    basePath,
    redirectTo = 'show',
    refresh = true) => ({
        type: 'RA/CRUD_UPDATE',
        payload: { id, data, previousData },
        meta: {
            resource,
            fetch: UPDATE,
            onSuccess: {
                notification: {
                    body: 'ra.notification.tips.updated',
                    level: 'info',
                    messageArgs: {
                        smart_count: 1,
                    },
                },
                refresh,
                redirectTo,
                basePath,
            },
            onFailure: {
                notification: {
                    body: 'ra.notification.http_error',
                    level: 'warning',
                },
            },
        }
})

class TipEditToolbarView extends React.Component {
    state = {
        isOpen: false,
    }

    handleSubmit = () => {
        const { handleSubmit } = this.props

        return handleSubmit(values => {
            this.props.updateTip(
                this.props.resource,
                this.props.record.id.toString(),
                this.props.form,
                this.props.record,
                this.props.basePath
            )
        })
    }

    openModal = () => {
        if (formsAreSimilar(this.props.record, this.props.form)) {
            window.history.back()
        } else {
            this.setState({ isOpen: true })
        }
    }

    handleDialogClose = () => {
        window.history.back()
        this.setState({ isOpen: false });
    };

    onConfirm = () => {
        this.setState({ isOpen: false });
        if (!this.props.invalid) {
            this.props.updateTip(
                this.props.resource,
                this.props.record.id.toString(),
                this.props.form,
                this.props.record,
                this.props.basePath
            )
        }
    }

    render() {
        return (
            <div>
                <Toolbar
                    handleSubmitWithRedirect={this.handleSubmit}
                    handleSubmit={this.handleSubmit}
                    invalid={this.props.invalid}
                    pristine={this.props.pristine}
                    redirect={this.props.redirect}
                    saving={this.props.saving}
                    submitOnEnter={this.props.submitOnEnter}>
                    <SaveButton />
                    <CancelButtonView onClick={this.openModal} />
                </Toolbar>
                <Confirm
                    title="Unsaved changed"
                    content="Are you sure yo want to quit?"
                    isOpen={this.state.isOpen}
                    onClose={this.handleDialogClose}
                    onConfirm={this.onConfirm}
                    cancel="Cancel changes"
                    confirm="Save changes"
                />
            </div>
        );
    }
}

export default connect(mapStateToProps, { updateTip })(TipEditToolbarView)

