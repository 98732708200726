import React from 'react';

import {
    Create,
    SimpleForm,
    TextInput,
    LongTextInput,
} from 'react-admin';

import { required } from '../utils'
import TipsCreateToolbar from './CreateTipToolbar'

export const TipCreate = (props) => (
    <Create {...props} >
        <SimpleForm toolbar={<TipsCreateToolbar {...props} />}>
            <TextInput source="name" label="Name *" validate={required()} />
            <LongTextInput source="text" label="Tip Text *" validate={required()} />
        </SimpleForm>
    </Create>
)