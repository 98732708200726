import React from 'react'

import moment from 'moment';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import Button from '@material-ui/core/Button';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Card from '@material-ui/core/Card';
import Chip from '@material-ui/core/Chip';

import './ExpandingTablePanel.css'

class ExpandingTablePanel extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            expanded: false,
            orderBy: 'id',
            order: 'desc'
        }
    }

    static defaultProps = {
        data: []
    }

    switchOrderType = (type) => {
        if (type == 'desc') {
            return 'asc'
        } else {
            return 'desc'
        }
    }

    togglePanel = () => {
        this.setState({
            expanded: !this.state.expanded
        })
    }

    createSortHandler = property => event => {
        let order = this.state.order
        if (this.state.orderBy === property) {
            order = this.switchOrderType(order)
        }

        this.setState({
            orderBy: property,
            order: order
        })
    }

    handleRequestSort = (event, property) => {
        
    }
    

    render() {
        const headCells = [
            { id: 'id', label: '#'},
            { id: 'value', label: "User's phrase" },
            { id: 'field', label: 'Searched by' },
            { id: 'datetime', label: 'Created' }
        ]
        const { data } = this.props
        const { expanded, order, orderBy } = this.state
        let slicedData = data.slice(0, expanded ? data.length : 5).map((item, index) => {
            return { id: index + 1, ...item }
        })
        slicedData = slicedData.sort((a, b) => {
            if (order == 'asc') {
                if (a[orderBy] > b[orderBy]) {
                    return -1
                } else {
                    return 1
                }
            } else {
                if (a[orderBy] > b[orderBy]) {
                    return 1
                } else {
                    return -1
                }
            }
        })
        return (
            <Card className="table">
                <p className="panel-title">Event failures</p>
                <p className="panel-desc">Top-{data.length} phrases that caused a skill failure</p>
                {data.length == 0 && <div>
                    <p className="empty-page-title">Seems ther is no data to show</p>
                    <p className="empty-page-desc">Good things come to those who wait</p>
                </div>}
                {data.length > 0 && <Table style={{ tableLayout: 'fixed', borderTop: '1px solid rgba(224, 224, 224, 1)', marginTop: '12px' }}>
                    <TableHead>
                        <TableRow style={{ height: '48px' }}>
                            {headCells.map(headCell => {
                                return (
                                    <TableCell 
                                        key={headCell.id}
                                        sortDirection={orderBy === headCell.id ? order : false}
                                        variant="head"
                                        classes={{ head: `custom-head-${headCell.id}` }}>
                                        <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={order}
                                            size="small"
                                            onClick={this.createSortHandler(headCell.id)}
                                        >
                                            {headCell.label}
                                        </TableSortLabel>
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {slicedData.map((item, index) => {
                            const date = moment.unix(item.datetime).format('MM/DD/YYYY hh:mm a')
                            return (
                                <TableRow key={index}>
                                    <TableCell style={{ width: '1%' }}>{item.id}</TableCell>
                                    <TableCell>{item.value}</TableCell>
                                    <TableCell>{item.field && <Chip label={item.field} />}</TableCell>
                                    <TableCell>{date}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell variant="footer">
                                <Button onClick={this.togglePanel} className="show-more">
                                    {expanded ? 'Show less' : 'Show more'}
                                    {expanded ? <ExpandLessIcon/> : <ExpandMoreIcon />}
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>}
            </Card>
        );
    }
}

export default ExpandingTablePanel