import React from 'react';
import { connect } from 'react-redux';
import { MenuItemLink, getResources, Responsive } from 'react-admin';
import { withRouter } from 'react-router-dom';
import DashboardIcon from '@material-ui/icons/Dashboard';

const MyMenu = ({ resources, onMenuClick, logout }) => {
    return (
        <div>
            <MenuItemLink to="/dashboard" leftIcon={<DashboardIcon />} primaryText="Dashboard" onClick={onMenuClick} />
            {resources.map(resource => (
                <MenuItemLink
                    key={resource.name}
                    to={`/${resource.name}`}
                    primaryText={resource.options && resource.options.label || resource.name}
                    leftIcon={resource.icon}
                    onClick={onMenuClick}
                />
            ))}
        </div>
    )
};
const mapStateToProps = state => ({
    resources: getResources(state),
});

export default withRouter(connect(mapStateToProps)(MyMenu));