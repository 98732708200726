import { AnswerCreate } from './AnswerCreate'
import { AnswerEdit } from './AnswerEdit'
import { QuestionsList } from './QuestionsList'
import { AnswerShow } from './AnswerShow'

export const questions = {
    list: QuestionsList,
    show: AnswerShow,
    edit: AnswerEdit,
    create: AnswerCreate
}