import React from 'react'
import { connect } from 'react-redux';

import {
    Confirm,
    SaveButton,
    Toolbar,
    CREATE
} from 'react-admin';

import { mapStateToProps, CancelButtonView } from '../utils'

const createAnswer = (
    resource,
    data,
    basePath,
    redirectTo = 'edit') => ({
        type: 'RA/CRUD_CREATE',
        payload: { data },
        meta: {
            resource,
            fetch: CREATE,
            onSuccess: {
                notification: {
                    body: 'ra.notification.answer.created',
                    level: 'info',
                    messageArgs: {
                        smart_count: 1,
                    },
                },
                redirectTo,
                basePath,
            },
            onFailure: {
                notification: {
                    body: 'ra.notification.http_error',
                    level: 'warning',
                },
            },
        }
    })

class AnswerCreateToolbarView extends React.Component {
    state = {
        isOpen: false,
    }

    handleSubmit = () => {
        const { handleSubmit } = this.props

        return handleSubmit(values => {
            this.props.createAnswer(this.props.resource, this.props.form, this.props.basePath, 'show')
        })
    }

    openModal = () => {
        const { form } = this.props
        if (form.hasOwnProperty('name') || form.hasOwnProperty('text')) {
            this.setState({ isOpen: true })
        } else {
            window.history.back()
        }
    }

    handleDialogClose = () => {
        window.history.back()
        this.setState({ isOpen: false });
    };

    onConfirm = () => {
        window.history.back()
        this.props.createAnswer(this.props.resource, this.props.form, this.props.basePath, 'show')
    }

    render() {

        return (
            <div>
                <Toolbar
                    handleSubmitWithRedirect={this.handleSubmit}
                    handleSubmit={this.handleSubmit}
                    invalid={this.props.invalid}
                    pristine={this.props.pristine}
                    redirect={this.props.redirect}
                    saving={this.props.saving}
                    submitOnEnter={this.props.submitOnEnter} >
                    <SaveButton />
                    <CancelButtonView onClick={this.openModal} />
                </Toolbar>
                <Confirm
                    title="Unsaved changed"
                    content="Are you sure yo want to quit?"
                    cancel="Cancel changes"
                    confirm="Save changes"
                    isOpen={this.state.isOpen}
                    onClose={this.handleDialogClose}
                    onConfirm={this.onConfirm}
                />
            </div>
        );
    }
}

export default connect(mapStateToProps, { createAnswer })(AnswerCreateToolbarView)