import React from 'react'

import {
    List,
    Datagrid,
    TextField,
    DateField,
    EditButton,
    Filter,
    TextInput,
    Show,
    SimpleShowLayout
} from 'react-admin';

const QuestionsFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="search" alwaysOn />
    </Filter>
);

const QuestionsPanelField = ({ record, source }) => {
    return (
        <ul>
            {record[source].map(item => (
                <li key={item.id}>{item.text}</li>
            ))}
        </ul>
    )
}
QuestionsPanelField.defaultProps = { addLabel: true };

const AnswerPanel = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <QuestionsPanelField source="questions" />
        </SimpleShowLayout>
    </Show >
);

export const QuestionsList = (props) => (
    <List {...props} filters={<QuestionsFilter />} exporter={false} sort={{ field: 'created_at', order: 'DESC' }}>
        <Datagrid rowClick="show" expand={<AnswerPanel />}>
            <TextField source="name" />
            <QuestionsPanelField label="Questions" source="questions" />
            <TextField label="Response" source="text" />
            <DateField source="created_at" label="Created" locales="en-US" />
            <EditButton />
        </Datagrid>
    </List>
);