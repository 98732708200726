import React from 'react';
import { AppBar, UserMenu, MenuItemLink } from 'react-admin';


const MyUserMenu = props => {
    const username = localStorage.getItem('username') || 'admin'
    return(
        <UserMenu {...props}>
            <MenuItemLink
                to="/"
                primaryText={username}
            />
        </UserMenu>
    )
};

const MyAppBar = props => (
    <AppBar {...props} userMenu={<MyUserMenu />} /> 
);

export default MyAppBar;