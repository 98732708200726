import { TipCreate } from './CreateTip'
import { TipEdit } from './EditTip'
import { TipsList } from './ListTips'
import { TipShow } from './ShowTip'

export const tips = {
    list: TipsList,
    create: TipCreate,
    edit: TipEdit,
    show: TipShow
}