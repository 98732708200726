import React from 'react'

import {
    Datagrid,
    ArrayField,
    TextField,
    Show,
    SimpleShowLayout,
} from 'react-admin';

export const AnswerShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="name" />
            <TextField source="text" />
            <ArrayField source="questions">
                <Datagrid>
                    <TextField source="text" label="" />
                </Datagrid>
            </ArrayField>
        </SimpleShowLayout>
    </Show >
);