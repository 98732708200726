import React from 'react'

import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import HelpIcon from '@material-ui/icons/Help';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import ErrorIcon from '@material-ui/icons/Error';

import "./NumberPanels.css"

class NumberPanels extends React.Component {
    render() {
        const { invocations, tipsinvocations, emailssent, qainvocations } = this.props

        return (
            <div className="panels">
                <Card className="number-panel">
                    <div className="icon-background">
                        <HelpIcon />
                    </div>
                    <div className="panel-text">
                        <p className="panel-text-title">Total interactions</p>
                        <p className="panel-text-number">{invocations}</p>
                    </div>
                </Card>
                <Card className="number-panel">
                    <div className="icon-background">
                        <AccountBoxIcon />
                    </div>
                    <div className="panel-text">
                        <p className="panel-text-title">Jack Tips invocations</p>
                        <p className="panel-text-number">{tipsinvocations}</p>
                    </div>
                </Card>
                <Card className="number-panel">
                    <div className="icon-background">
                        <InsertInvitationIcon />
                    </div>
                    <div className="panel-text">
                        <p className="panel-text-title">Email sent</p>
                        <p className="panel-text-number">{emailssent}</p>
                    </div>
                </Card>
                <Card className="number-panel">
                    <div className="icon-background">
                        <ErrorIcon />
                    </div>
                    <div className="panel-text">
                        <p className="panel-text-title">Pecan Square invocations</p>
                        <p className="panel-text-number">{qainvocations}</p>
                    </div>
                </Card>
            </div>
        )
    }
}

export default NumberPanels