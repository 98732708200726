import React from 'react'

import {
    Edit,
    SimpleForm,
    ArrayInput,
    SimpleFormIterator,
    TextInput
} from 'react-admin';

import { required, CustomTextField } from '../utils'
import AnswerEditToolbar from './AnswerEditToolbar'

export const AnswerEdit = (props) => (
    <Edit {...props}>
        <SimpleForm toolbar={<AnswerEditToolbar />}>
            <CustomTextField
                source="name"
                baselabel="Name of the question *"
                textlabel="Short label for fast identification"
                validate={required()} />
            <ArrayInput source="questions">
                <SimpleFormIterator>
                    <TextInput source="text" validate={required()} />
                </SimpleFormIterator>
            </ArrayInput>
            <CustomTextField
                source="text"
                baselabel="Response *"
                textlabel="What Alexa will answer and what will display in return to the user's question"
                validate={required()} />
        </SimpleForm>
    </Edit>
);